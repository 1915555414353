import { createApp } from 'vue'
import App from './App.vue'
import $ from 'jquery'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Toast, Dialog, Cell, CellGroup, Field, Button, Icon,RadioGroup, Radio,Badge,List,Image,Col,Row } from 'vant';


window.$ = $


const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.title){
      document.title = to.meta.title
    }
    next();
});

const app = createApp(App)
// app.config.productionTip = false;
// app.config.globalProperties.$copterm = {
//   title: 'My Awesome Vue App',
// };
// // 设置网站图标
// const favicon = '/favicon.ico';
// const link = document.createElement('link');
// link.rel = 'icon';
// link.href = favicon;
// document.head.appendChild(link);
app.use(VueAwesomeSwiper)
app.use(router)
app.use(Toast)
  .use(Dialog)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Button)
  .use(Icon)
  .use(RadioGroup)
  .use(Radio)
  .use(Badge)
  .use(List)
  .use(Image)
  .use(Col)
  .use(Row)

app.mount('#app')