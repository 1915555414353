<template>
    <header class="aui-header-default aui-header-fixed t-bg">
        <div class="aui-header-center aui-header-center-clear">
            <div class="aui-header-center-logo">
                <div>
                    <img src="/images/pdx.png" alt="">
                </div>
            </div>
        </div>

    </header>
    <div class="clear"></div>

    <section class="n-tabs" style="text-align: center;">
        <ul class="n-tabContainer" id="auto-id-1509603311057">
            <li class="n-tabItem" id="home">
                <a class="current">{{ title }}</a>
            </li>
        </ul>
    </section>
    <div class="clear"></div>
    <div class="hbox1"></div>
    <div class="tolist" @click="changeList()">
        <img src="../../static/images//tolist.png">
    </div>
    <div class="totop">
        <a href="javascript:scrollTo(0,0)">
            <img src="../../static/images//totop.png"></a>
    </div>
    <div class="kbox"></div>
    <div class="likebox" v-show="isShowLikeBox">
        <ul>


            <li v-for="(item, index) in productList">
                <a v-bind:href="`/taxRefund/detail/${item.id}`">
                    <div class="image-wrapper">
                        <img :src="item.productImg" :alt="item.productName" />
                    </div>
                    <p class="tit">{{ item.productName }}</p>
                    <p class="price">￥{{ item.groupPrice }}<span>￥{{ item.marketPrice }}</span></p>
                    <p class="price"
                        style="background-color: #F79764; text-align: center; vertical-align: middle; line-height: 11px; color: red">
                        返税:￥{{ item.taxReturned }}</p>
                </a>
            </li>

        </ul>
    </div>
    <div class="shoplist" style="display: none" v-show="!isShowLikeBox">
        <ul>


            <li v-for="(item, index) in productList">
                <a v-bind:href="`/taxRefund/detail/${item.id}`">
                    <div class="listL">
                        <img :src="item.productImg" :alt="item.productName" class="proimg" />
                    </div>
                    <div class="listR">
                        <div class="v1">{{ item.productName }}</div>
                        <div class="v2"><span>返税:{{ item.taxReturned }}</span></div>
                        <div class="v3">
                            <p class="p1">￥{{ item.groupPrice }}<span>￥{{ item.marketPrice }}</span></p>
                            <p class="p2">{{ item.salesVol }}人付款</p>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <br><br><br>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getProductList, getPromoCategory } from '@/api/taxRefund.js'
import { useRoute } from 'vue-router'
const route = useRoute()
const promoId = route.query.promoId
const productList = ref([])
const isShowLikeBox = ref(true)
const title = ref('')
const changeList = () => {
    isShowLikeBox.value = !isShowLikeBox.value
}
onMounted(() => {
    getProductList(promoId).then(res => {
        if (res && res.rows) {
            productList.value = res.rows
        }
    })

    getPromoCategory(promoId).then(res => {
        if (res && res.data) {
            title.value = res.data.promoName
        }
    })

})

</script>

<style>
.likebox ul li p.price {
    color: #ff2150;
    padding-top: 0.5rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
}


.image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

}

.image-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>